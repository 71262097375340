import { Link } from "gatsby"
import React from "react"
import { Helpers } from "../../services/Helpers"
import { Hike } from "../../models/NationalPark"
import Colors from "../../constants/Colors"
import { FiClock } from "react-icons/fi"
import { RiPencilRuler2Line } from "react-icons/ri"
import Variables from "../../constants/Variables"
import Routes from "../../constants/Routes"

interface Props {
  hike: Hike
  parkname: string
}

export default function HikingCard({ hike, parkname }: Props) {
  const link = `/${Helpers.sanitizeUrl(parkname)}/${
    Routes.HIKE_ROUTE
  }${Helpers.sanitizeUrl(hike.hike_name[0].text)}/`

  return (
    <div className="max-w-sm lg:max-w-md rounded overflow-hidden shadow-lg mb-6 mr-0 md:mr-4">
      <Link to={link} title={hike.hike_name[0].text}>
        <div
          style={{
            background: `url(${hike.hike_image.url})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: 400,
          }}
        ></div>
      </Link>
      <div className="px-6 py-4 border-b border-lightest h-28">
        <Link to={link}>
          <h4 className="mt-0 mb-1">{hike.hike_name[0].text}</h4>
          <FiClock
            size={14}
            className="inline-block"
            color={Colors.themeColor}
            style={{ marginBottom: 2 }}
          />
          <span style={styles} className="ml-2">
            {hike.hike_time} hours
          </span>
          <span style={character} className="ml-2">
            •
          </span>
          <RiPencilRuler2Line
            size={14}
            className="inline-block"
            color={Colors.themeColor}
            style={{ marginBottom: 2 }}
          />
          <span style={styles} className="ml-2">
            {hike.hike_length} kilometers
          </span>
          <div
            className="inline-block text-xs mr-2 mt-1 py-1 px-2 bg-light rounded-lg hover:bg-light focus:outline-none font-bold float-right"
            style={{ color: Colors.almostBlack }}
          >
            {hike.hike_difficulty}
          </div>
          <div className="mt-0 mb-2"></div>
        </Link>
      </div>
    </div>
  )
}

const styles = {
  color: Colors.grey,
  fontSize: 12,
} as React.CSSProperties

const character = {
  color: Colors.themeColor,
  fontSize: 12,
  fontFamily: Variables.fontFamilyHeadline,
  marginRight: 10,
} as React.CSSProperties
