import React from 'react';
import { FaWalking } from 'react-icons/fa';
import { FiClock } from 'react-icons/fi';
import { RiPencilRuler2Line } from 'react-icons/ri';
import { GiPathDistance } from 'react-icons/gi';
import Colors from '../../constants/Colors';
import { Hike } from '../../models/NationalPark';
import CustomCard from '../cards/CustomCard';

interface Props {    
    title: string;
    hike: Hike
}

export default function HikeSidebar({ 
    title,
    hike
}: Props) {
  return (
    <div className="col-span-12 md:col-span-4 pr-6">
        <CustomCard className="pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
            <h4 className="mt-0 mb-3">
                {title}
            </h4>
            <div className="bg-lightest rounded-sm pt-2 pb-1 px-2">
              <p className="inline-block mt-0 mb-0 align-text-bottom text-sm">Hike Time</p>
              <p className="inline-block mt-0 mb-0 align-text-bottom text-sm float-right w-20 font-bold">
                <FiClock 
                      size={14} 
                      className="inline-block"
                      color={Colors.themeColor}
                      style={{ marginBottom: 2, marginRight: 5 }}
                />
                {hike.hike_time} hours
              </p>
            </div>
            <div className="rounded-sm pt-2 pb-1 px-2">
              <p className="inline-block mt-0 mb-0 align-text-bottom text-sm">Hike Length</p>
              <p className="inline-block mt-0 mb-0 align-text-bottom text-sm float-right w-20 font-bold">
                <RiPencilRuler2Line 
                      size={14} 
                      className="inline-block"
                      color={Colors.themeColor}
                      style={{ marginBottom: 2, marginRight: 5 }}
                />
                {hike.hike_length} km
              </p>
            </div>
            <div className="bg-lightest rounded-sm pt-2 pb-1 px-2">
              <p className="inline-block mt-0 mb-0 align-text-bottom text-sm">Trail Difficulty</p>
              <p className="inline-block mt-0 mb-0 align-text-bottom text-sm float-right w-20 font-bold">
                <FaWalking 
                      size={14} 
                      className="inline-block"
                      color={Colors.themeColor}
                      style={{ marginBottom: 2, marginRight: 5 }}
                />
                {hike.hike_difficulty}
              </p>
            </div>
            {hike.hike_type !== null && hike.hike_type !== undefined && (
              <div className="rounded-sm pt-2 pb-1 px-2">
                <p className="inline-block mt-0 mb-0 align-text-bottom text-sm">Trail Type</p>
                <p className="inline-block mt-0 mb-0 align-text-bottom text-sm float-right w-20 font-bold">
                  <GiPathDistance 
                        size={14} 
                        className="inline-block"
                        color={Colors.themeColor}
                        style={{ marginBottom: 2, marginRight: 5 }}
                  />
                  {hike.hike_type}
                </p>
              </div>
            )}
        </CustomCard>
      </div>
  );
}